import React from 'react';
import iconDownloadColored from "../../images/icons/icon-download-colored.svg";

import './index.scss';

export default function Rapport({exercice, rapportAuxiliaire, rapportAuxiliaireVie, titre}) {
    return (<div className="divLink">
            de{" "}
            <a href={rapportAuxiliaire} className="btn-file--3" download>
                <img src={iconDownloadColored} alt="icone download"/>
                <span>{titre || "L'Auxiliaire"}</span>
            </a>
            {rapportAuxiliaireVie && <>
                {" "}ou de{" "}
                <a href={rapportAuxiliaireVie} className="btn-file--3" download>
                    <img src={iconDownloadColored} alt="icone download"/>
                    <span>L'Auxiliaire Vie</span>
                </a>
            </>}
            {" "}sur l'exercice {exercice}
        </div>)
}
