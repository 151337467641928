import React from "react";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/mousewheel";

SwiperCore.use([Mousewheel, Navigation]);

import "./numbersCarousel.scss";

import NumbersItem from "./numbersItem";

export default function NumberCarousel({ numbers }) {
  return (
    <div className="numbers-carousel">
      {numbers.length === 1 ? (
        <NumbersItem number={numbers[0]} />
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          centeredSlides={true}
          mousewheel= {{
            releaseOnEdges: true,
          }}
          onReachEnd={(swiper) => {
            setTimeout(function () {
                swiper.params.mousewheel.releaseOnEdges = true;
            }, 800);
          }}
          onReachBeginning={(swiper) => {
            setTimeout(function () {
                swiper.params.mousewheel.releaseOnEdges = true;
            }, 800);
          }}
          onSlideChange={(swiper)=>{
            swiper.params.mousewheel.releaseOnEdges = false;
          }}
          roundLengths={true}
          direction={"horizontal"}
          speed={800}
          navigation={true}
          breakpoints={{
            640: {
              slidesPerView: 3,
            },
          }}
        >
          {numbers.map((number, index) => (
            <SwiperSlide key={index}>
              <NumbersItem number={number} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
