import React from "react";

import "./numbersCarousel.scss";

export default function NumbersItem({ number }) {
  return (
    <div className="numbers-carousel__slide">
      {number.left_text && <span className="numbers-carousel__name">{number.left_text}</span>}
      <span className="numbers-carousel__number">{number.Number}</span>
      {number.right_text && <span className="numbers-carousel__name">{number.right_text}*</span>}
    </div>
  );
}
