import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import "./partenairesCarousel.scss";

export default function PartenairesItem({ partenaire }) {
  const logo = getImage(partenaire.Logo.localFile);
  return (
    <div className="partenaires-carousel__slide">
      <GatsbyImage
        className="partenaires-carousel__img"
        image={logo}
        alt={`logo de ${partenaire.Nom}`}
      />
      <br></br>
        <div className="partenaires-carousel__text">
            <h3 className="partenaires-carousel__name">{partenaire.Nom}</h3>
            <ReactMarkdown
                className="markdown"
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                children={partenaire.Description.data.Description}
            />
        </div>
      
    </div>
  );
}
