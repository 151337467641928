import React from "react";
import SwiperCore, {Navigation, Pagination} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

SwiperCore.use([Navigation]);

import "./partenairesCarousel.scss";

import PartenairesItem from "./partenairesItem";

export default function PartenairesCarousel({ partenaires }) {

  function onSwiperClick(event) {
    const indexChanged = event.clickedIndex - event.activeIndex;

    if(indexChanged !== 0) {
     if(indexChanged > 0) {
       event.slideNext();
     }
     else {
       event.slidePrev();
     }
    }
  }

  return (
    <div className="partenaires-carousel">
        <h2>
            L'Auxiliaire, une mutuelle <strong>ancrée dans l'univers du BTP</strong>
        </h2>

      {partenaires.length === 1 ? (
        <PartenairesItem partenaire={partenaires[0]} />
      ) : (
        <Swiper modules={[Navigation, Pagination]}
                onClick={(event) => {
                  onSwiperClick(event);
                }}
          slidesPerView={1}
          spaceBetween={1}
          centeredSlides={true}
          roundLengths={true}
          loopAdditionalSlides={30}
          navigation = {true}
          pagination={{ clickable: true }}
          loop={true}
          breakpoints={{
            1000: {
              slidesPerView: 3,
            },
          }}
        >
          {partenaires.map((partenaire, index) => (
            <SwiperSlide key={index}>
                    <PartenairesItem  partenaire={partenaire}  />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
